import { Axios } from 'axios'
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            // baseURL: "http://localhost:4000"
            baseURL: "https://api.encyclopediaofmuhammad.org/"
        }
    },
    computed: {
        ...mapGetters(["isWaiting", "isLogin", "isMenu", "toast", "token", "profile"])
    },
    methods: {
        isJson(str){
            try{
                const val = JSON.parse(str)
                return val["title"]
            }catch(err){
                `${err}`;
                return "";
            }
        },
        getState(){
            return this.$store.state;
        },
        toggleMenu(){
            this.$store.commit("toggleMenu");
        },
        toggleWaiting(state = false){
            this.$store.commit("toggleWaiting", state)
        },
        toggleDialog(state = false){
            this.$store.commit("toggleDialog", state)
        },
        setDialogOptions(state){
            this.$store.commit("setDialogOptions", state)
        },
        getAPI(){
            let headers = {
                "Content-Type": "application/json"
            };
            if(this.token){
                headers["Authorization"] = `Bearer ${this.token}`;
            }
            return new Axios({
                baseURL: this.baseURL,
                headers
            });
        },
        async getAll(url){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().get(url)
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
        },
    
        async getOne(url, id){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().get(`${url}/${id}`).catch(this.errorHandle)
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
        },
    
        async insert(url, payload){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().post(url, JSON.stringify(payload)).catch(this.errorHandle)
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
        },
        async update(url, id, payload){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().put(`${url}/${id}`, JSON.stringify(payload)).catch(this.errorHandle)
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
        },
        async remove(url, id){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().delete(`${url}/${id}`).catch(this.errorHandle)
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
        },
        async upload(url,formData){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).catch(this.errorHandle);
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
        },
        async getProfile(){
            const {data, status} = await this.getAPI().get("/auth/me")
            if (status >= 200 && status < 300){
                if(data){
                    this.$store.commit("setProfile", data);
                }
            }
        },
        async login(payload){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().post("/auth/login", JSON.stringify(payload))
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                if(data){
                    this.$store.commit("toggleLogin");
                    this.$store.commit("setToken", data);
                }
                return data;
            }else{
                this.errorHandle(data);
            }
            return;
        },
        async register(payload){
            this.toggleWaiting(true);
            const {data, status} = await this.getAPI().post("/auth/register", JSON.stringify(payload))
            if (status >= 200 && status < 300){
                this.toggleWaiting(false);
                return data;
            }else{
                this.errorHandle(data);
            }
            return;
        },
        errorHandle(err){
            this.$store.commit("showToast", JSON.parse(err).message);
            this.toggleWaiting(false);
        }
    }
}